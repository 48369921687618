import { WppBreadcrumbCustomEvent } from '@platform-ui-kit/components-library/dist/types/components'
import { BreadcrumbItemEventDetails } from '@platform-ui-kit/components-library/dist/types/components/wpp-breadcrumb/types'
import { WppBreadcrumb, WppIconChevron, WppTypography } from '@platform-ui-kit/components-library-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { LocationStateFrom } from 'types/common/urls'
import { useBreadcrumbsConfig } from 'ui-shared/breadcrumbs/useBreadcrumbsConfig'
import { RoutesManager } from 'utils/routesManager'

import * as S from 'ui-shared/breadcrumbs/Breadcrumbs.styled'

interface Props {
  breadcrumbLabel?: string
}

export const Breadcrumbs = ({ breadcrumbLabel }: Props) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation(['products', 'versions'])
  const { state } = useLocation()
  const { productId, versionId } = useParams<{ productId: string; versionId: string }>()
  const { breadcrumbsItems, isCollaborationPage, isDashboardPage, isVersionLaunchPage, isCollectionsListPage } =
    useBreadcrumbsConfig(location.pathname, breadcrumbLabel)

  const customParams = useMemo(() => {
    if (isDashboardPage) {
      return {
        path: RoutesManager.myProducts.root.getURL(),
        label: t('products|breadcrumbs.back_to_products'),
      }
    }
    if (isCollaborationPage) {
      return {
        path: RoutesManager.products.root.getURL({ productId: productId! }),
        label: t('products|breadcrumbs.back_to_dashboard'),
      }
    }
    if (isVersionLaunchPage) {
      return {
        path: RoutesManager.products.versions.update.getURL({ productId: productId!, versionId: versionId! }),
        label: t('versions|launch_to_test.back_button', { versionName: breadcrumbLabel }),
      }
    }
    if (isCollectionsListPage) {
      return {
        path: RoutesManager.productsV2.root.getURL(),
        label: t('products|breadcrumbs.back_to_products'),
      }
    }
  }, [
    isDashboardPage,
    isCollaborationPage,
    isVersionLaunchPage,
    isCollectionsListPage,
    t,
    productId,
    versionId,
    breadcrumbLabel,
  ])

  if (isDashboardPage || isCollaborationPage || isVersionLaunchPage || isCollectionsListPage) {
    const from: LocationStateFrom = state?.from

    const handleClick = () => {
      navigate({
        pathname: from?.path || customParams?.path!,
        hash: from?.hash,
      })
    }

    return (
      <S.BackWrapper onClick={handleClick} align="center" gap={4} data-testid="back-button">
        <WppIconChevron direction="left" />{' '}
        <WppTypography type="s-body">{from?.label || customParams?.label!}</WppTypography>
      </S.BackWrapper>
    )
  }

  return (
    <WppBreadcrumb
      items={breadcrumbsItems}
      onWppChange={(e: WppBreadcrumbCustomEvent<BreadcrumbItemEventDetails>) => {
        navigate(e.detail.path)
      }}
      data-testid="breadcrumbs"
    />
  )
}
