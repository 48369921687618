import { StepChangeEventDetail } from '@platform-ui-kit/components-library'
import { useCallback } from 'react'
import { FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { CreateCollectionSideModalState } from 'pages/collections/common/createCollectionSideModal/CreateCollectionSideModal'
import { CreateCollectionFormValues } from 'pages/collections/common/createCollectionSideModal/formUtils'

import * as S from 'pages/collections/common/createCollectionSideModal/components/stepper/Stepper.styled'

interface Props {
  errors: FieldErrors<CreateCollectionFormValues>
  collectionName: string
  currentStep: number
  elementsCount: number
  setState: (props: Partial<CreateCollectionSideModalState>) => void
}

export const Stepper = ({ errors, collectionName, currentStep, elementsCount, setState }: Props) => {
  const { t } = useTranslation(['collections'])
  const firstStepError = !!errors.name || !!errors.shortDescription
  const secondStepError = !!errors.elements
  const isFirstStepCompleted = !firstStepError && !!collectionName
  const isSecondStepCompleted = !secondStepError && !!elementsCount

  const handleStepClick = useCallback(
    (event: CustomEvent<StepChangeEventDetail>) => {
      const clickedIndex = event.detail.index

      if (!clickedIndex) return

      setState({ currentStep: clickedIndex })
    },
    [setState],
  )

  return (
    <S.Stepper
      activeStep={currentStep}
      orientation="horizontal"
      onWppChange={handleStepClick}
      data-testid="collection-creation-stepper"
    >
      <S.Step error={firstStepError} completed={isFirstStepCompleted}>
        <p slot="label" data-testid="collection-creation-first-step-label">
          {t('collections|create_collection.steps.collection_info')}
        </p>
      </S.Step>
      <S.Step active={isFirstStepCompleted} error={secondStepError} completed={isSecondStepCompleted}>
        <p slot="label" data-testid="collection-creation-second-step-label">
          {t('collections|create_collection.steps.products')}
        </p>
      </S.Step>
    </S.Stepper>
  )
}
