import { devHubApi } from 'api'
import { CreateCollectionFormDTO } from 'pages/collections/common/createCollectionSideModal/formUtils'
import { CollectionDTO } from 'types/collections/collection'

interface Params {
  collectionData: CreateCollectionFormDTO
}

export const createCollectionApi = ({ collectionData }: Params) =>
  devHubApi.post<CollectionDTO>('/collections', collectionData)
