import { AccessActions } from 'types/permissions/enums'
import { NoCodeAppType, ProductOnboardingStatus, ProductType } from 'types/products/enums'
import { AppVersionDTO, ProductCategoryDTO } from 'types/products/product'

export enum CollectionStatus {
  DRAFT = 'DRAFT',
  OS = 'OS',
  MARKETPLACE = 'MARKETPLACE',
  OS_AND_MARKETPLACE = 'OS_AND_MARKETPLACE',
}

export interface CollectionDTO {
  id: string
  name: string
  shortDescription?: string
  elements: CollectionProductDTO[]
  collectionStatus: CollectionStatus
  createdAt: string
  createdByEmail: string
  updatedAt: string
  updatedByEmail: string
}

export interface CollectionProductDTO {
  productId: string
  productName: string
  productType: ProductType
  noCodeAppType?: NoCodeAppType
  applicationCategory?: ProductCategoryDTO
  logoUrl: string
  //for no-code apps - product description, for no-code types - version description
  shortDescription?: string
  versionId: string
  versionName: string
}

//new type for list of my products + versions to add version to a collection
export interface CollectionProductWithVersionsDTO {
  id: string
  name: string
  productType: ProductType
  noCodeAppType?: NoCodeAppType
  shortDescription?: string
  logoUrl: string
  versions: CollectionsShortVersionDTO[]
  accessActions: AccessActions[]
  publishOnboardingStatus: ProductOnboardingStatus
  createdAt: string
  createdByEmail: string
  updatedAt: string
  updatedByEmail: string
}

export type CollectionsShortVersionDTO = Pick<AppVersionDTO, 'id' | 'name' | 'description'>
